<template>
  <div>
    <v-card class="radius_intab custom_card pa-5">
      <div class="toolbar d-flex">
        <div style="width: 70%">
          <v-row>
            <v-col cols="5">
              <v-text-field
                flat
                label="Cari Pengguna"
                class="custom_field"
                background-color="#F1FBFC"
                solo
                @keyup="search"
                v-model="local_find"
                hide-details="auto"
                prepend-inner-icon="mdi-magnify"
                color="blue"
              ></v-text-field>
            </v-col>

            <v-col cols="3">
              <v-menu
                v-model="menuLimit"
                bottom
                :nudge-bottom="55"
                elevation="0"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-card
                    class="pa-3 d-flex align-center"
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    height="100%"
                  >
                    <p class="ma-0 mr-2 color_txt small_txt">
                      Show : {{ limit }}
                    </p>
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-card>
                </template>
                <v-card class="popup_radius" outlined elevation="0">
                  <v-list>
                    <v-list-item link @click="changeLimit(10)">
                      <v-list-item-title>10</v-list-item-title>
                    </v-list-item>
                    <v-list-item link @click="changeLimit(50)">
                      <v-list-item-title>50</v-list-item-title>
                    </v-list-item>
                    <v-list-item link @click="changeLimit(100)">
                      <v-list-item-title>100</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-menu>
            </v-col>
          </v-row>
        </div>
        <div class="d-flex justify-end align-center" style="width: 30%">
          <v-btn
            depressed
            rounded
            dark
            color="#00b4cc"
            class="text-capitalize"
            @click="dialogCreate = true"
          >
            <p class="my-2">Tambah</p>
            <v-icon class="ml-2">mdi-account-plus</v-icon>
          </v-btn>
        </div>
      </div>
      <v-skeleton-loader type="table-tbody" v-if="loading"></v-skeleton-loader>

      <div class="mt-5" v-if="users && !loading">
        <v-data-table
          :headers="headers"
          :items="users"
          :page.sync="page"
          :items-per-page="limit"
          class="elevation-0 color_txt"
          hide-default-footer
          :item-class="row_classes"
          :single-select="false"
        >
          <template v-slot:[`item.province_name`]="{ item }">
            <div v-if="item">
              <p class="ma-0">{{ capitalizeText(item.province_name) }}</p>
            </div>
          </template>

          <template v-slot:[`item.regency_name`]="{ item }">
            <div v-if="item">
              <p class="ma-0">{{ capitalizeText(item.regency_name) }}</p>
            </div>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <div v-if="item">
              <v-btn
                small
                depressed
                color="#00ccb6"
                class="action_btn rounded-lg"
                min-width="10px"
                height="35px"
                dark
                @click="viewUser(item)"
                ><span class="text-capitalize font-weight-bold mx-2 my-2"
                  >Lihat Profil</span
                ></v-btn
              >
            </div>
          </template>
        </v-data-table>
        <div class="d-flex justify-center pa-3">
          <div class="d-flex align-center pa-2 round_paginate">
            <v-btn
              small
              min-width="10px"
              color="#00b4cc"
              dark
              class="pa-2 custom_shadow"
              @click="pagination('prev')"
              ><v-icon small>mdi-arrow-left</v-icon></v-btn
            >
            <div class="mx-3">
              <p class="ma-0 color_default">
                {{ page }} of {{ Math.ceil(total / limit) }}
              </p>
            </div>
            <v-btn
              small
              min-width="10px"
              color="#00b4cc"
              dark
              class="pa-2 custom_shadow"
              @click="pagination('next')"
              ><v-icon small>mdi-arrow-right</v-icon></v-btn
            >
          </div>
        </div>
      </div>
    </v-card>
    <div class="pa-2">
      <CreateUser
        v-bind:dialogCreate="dialogCreate"
        @close="dialogCreate = false"
        @refetch="refetch()"
      />
      <UpdateUser
        v-bind:dialogUpdateUser="dialogUpdateUser"
        @close="dialogUpdateUser = false"
        v-bind:dataUser="dataUser"
        @refetch="refetch()"
        @pwd="showPwd"
        ref="update"
      />
      <DeleteUser
        v-bind:dialogDeleteUser="dialogDeleteUser"
        v-bind:dataUser="dataUser"
        @refetch="refetch()"
        @close="dialogDeleteUser = false"
      />
      <ChangePassword
        v-bind:dialogChangePwd="dialogChangePwd"
        @close="dialogChangePwd = false"
        @walrus="backFromChangePwd"
        v-bind:dataUser="dataUser"
      />
      <ChangeStatusUser
        v-bind:dialogChangeStatus="dialogChangeStatus"
        @close="dialogChangeStatus = false"
        @refetch="refetch()"
        v-bind:dataUser="dataUser"
      />
    </div>
  </div>
</template>
  
<script>
import { ROLES } from "../../../constants/constant";
import DetailInstance from "../../dataFasyankes/detailInstance.vue";
import UpdateUser from "..//Update/updateUser.vue";
import CreateUser from "../Create/createUser.vue";
import DeleteUser from "../Delete/deleteUser.vue";
import ChangePassword from "../Update/changePassword.vue";
import ChangeStatusUser from "../Update/changeStatusUser.vue";
export default {
  name: "TableDinkes",
  props: {
    users: {
      type: Array,
      default: [],
    },
    role: {
      type: String,
      default: "",
    },
    page: {
      type: Number,
      default: 1,
    },
    limit: {
      type: Number,
      default: 10,
    },
    total: {
      type: Number,
      default: 0,
    },
    find: {
      type: String,
      default: "",
    },
    has_prev: {
      type: Boolean,
      default: false,
    },
    has_next: {
      type: Boolean,
      default: false,
    },
    tab: {
      type: String,
      default: "",
    },
  },

  components: {
    CreateUser,
    DeleteUser,
    UpdateUser,
    ChangePassword,
    ChangeStatusUser,
    DetailInstance,
  },
  data() {
    return {
      ROLES,
      timer_id: null,
      loading: false,
      dataUser: null,
      dataItem: null,
      tipe_fasyankes: "",
      access_right: "Administrator",
      menu: false,
      menuLimit: false,
      dialogDeleteUser: false,
      dialogUpdateUser: false,
      dialogChangePwd: false,
      dialogChangeStatus: false,
      dialogCreate: false,
      items: ["Foo", "Bar", "Fizz", "Buzz"],
      headers: [
        {
          text: "Nama Instansi",
          align: "start",
          value: "nama_instansi",
        },
        {
          text: "Provinsi",
          align: "start",
          value: "province_name",
        },
        { text: "Action", sortable: false, value: "actions" },
      ],

      // props
      local_users: this.users,
      local_role: this.role,
      local_page: this.page,
      local_limit: this.limit,
      local_total: this.total,
      local_find: this.find,
    };
  },
  mounted() {
    
    if (this.tab == ROLES.PublicHealthService_Regency) {
      this.headers = [
        {
          text: "Nama Instansi",
          align: "start",
          value: "nama_instansi",
        },
        {
          text: "Provinsi",
          align: "start",
          value: "province_name",
        },
        {
          text: "Kabupaten/Kota",
          align: "start",
          value: "regency_name",
        },
        { text: "Action", sortable: false, value: "actions" },
      ];
    }
  },

  methods: {
    capitalizeText(text) {
      return text.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
    },
    refetch() {
      this.$emit("refetch");
    },
    search() {
      this.$emit("update:find", this.local_find);
    },
    changeLimit(limit) {
      this.$emit("update:limit", limit);
    },
    row_classes(item) {
      let idx = this.users.indexOf(item);
      if (idx % 2 == 0) {
        return "strip_blue";
      }
    },
    showPwd() {
      this.dialogUpdateUser = false;
      this.dialogChangePwd = true;
    },
    backFromChangePwd() {
      this.dialogChangePwd = false;
      this.dialogUpdateUser = true;
    },
    viewUser(item) {
      this.dataUser = item;
      this.dialogUpdateUser = true;
      this.$refs.update.getPerson(item.id);
    },
    changeStatus(item) {
      this.dataUser = item;
      this.dialogChangeStatus = true;
    },
    delUser(item) {
      this.dataUser = item;
      this.dialogDeleteUser = true;
    },
    pagination(state) {
      if (state == "next") {
        if (this.has_next) {
          this.local_page++;
          this.$emit("update:page", this.local_page);
        }
      } else {
        if (this.has_prev) {
          this.local_page--;
          this.$emit("update:page", this.local_page);
        }
      }
    },
  },
};
</script>
  
<style scoped>
* {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

.v-btn__content {
  min-width: none !important;
  width: 30px !important;
}

.round_paginate {
  border: 1px solid rgba(215, 215, 215, 1);
  border-radius: 10px;
  width: max-content;
}
</style> 