<template>
  <div>
    <v-card class="radius_intab custom_card pa-5">
      <div class="toolbar d-flex">
        <div style="width: 60%">
          <v-row>
            <v-col cols="6">
              <v-text-field
                flat
                label="Cari Pengguna"
                class="custom_field"
                background-color="#F1FBFC"
                solo
                @keyup="allFilter"
                hide-details="auto"
                prepend-inner-icon="mdi-magnify"
                color="blue"
              ></v-text-field>
            </v-col>

            <v-col cols="3">
              <v-menu
                v-model="menuLimit"
                bottom
                :nudge-bottom="55"
                elevation="0"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-card
                    class="pa-3 d-flex align-center"
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    height="100%"
                  >
                    <p class="ma-0 mr-2 color_txt small_txt">
                      Show: {{ limit }}
                    </p>
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-card>
                </template>
                <v-card class="popup_radius" outlined elevation="0">
                  <v-list>
                    <v-list-item link @click="changeLimit(10)">
                      <v-list-item-title>10</v-list-item-title>
                    </v-list-item>
                    <v-list-item link @click="changeLimit(50)">
                      <v-list-item-title>50</v-list-item-title>
                    </v-list-item>
                    <v-list-item link @click="changeLimit(100)">
                      <v-list-item-title>100</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-menu>
            </v-col>
          </v-row>
        </div>
        <div class="d-flex justify-end align-center" style="width: 40%">
          <v-btn
            depressed
            rounded
            dark
            color="#00b4cc"
            class="text-capitalize"
            @click="dialogCreate = true"
          >
            <p class="my-2">Tambah</p>
            <v-icon class="ml-2">mdi-account-plus</v-icon>
          </v-btn>
        </div>
      </div>
      <v-skeleton-loader type="table-tbody" v-if="loading"></v-skeleton-loader>

      <div class="mt-5" v-if="users && !loading">
        <v-data-table
          :headers="headers"
          :items="hospitals"
          :page.sync="page"
          :items-per-page="limit"
          class="elevation-0 color_txt"
          hide-default-footer
          :item-class="row_classes"
          :single-select="false"
        >
          <template v-slot:[`item.nama_fasyankes`]="{ item }">
            <span>{{ sliceAndEllipsis(item.nama_fasyankes) }}</span>
          </template>
          <template v-slot:[`item.full_name`]="{ item }">
            <span>{{ sliceAndEllipsis(item.full_name) }}</span>
          </template>
          <template v-slot:[`item.isActive`]="{ item }">
            <v-switch
              v-if="item.isActive"
              hide-details="auto"
              class="mt-0"
              inset
              @click="changeStatus(item)"
              v-model="item.status"
            ></v-switch>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <p class="ma-0">
              {{ item.isActive == "NonActive" ? "Tidak Aktif" : "Aktif" }}
            </p>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div v-if="item">
              <v-btn
                small
                depressed
                color="#00ccb6"
                class="mr-1 action_btn"
                dark
                @click="viewUser(item)"
                ><v-icon>mdi-eye-outline</v-icon></v-btn
              >
              <v-btn
                small
                depressed
                color="#00879a"
                class="mr-1 px-1"
                dark
                @click="delUser(item)"
              >
                <v-icon>mdi-trash-can-outline</v-icon></v-btn
              >
            </div>
          </template>
        </v-data-table>
        <div class="py-3 d-flex justify-center">
          <div class="d-flex align-center pa-2 round_paginate">
            <v-btn
              small
              min-width="10px"
              color="#00b4cc"
              dark
              class="pa-2 custom_shadow"
              @click="pagination('prev')"
              ><v-icon small>mdi-arrow-left</v-icon></v-btn
            >
            <div class="mx-3">
              <p class="ma-0 color_default">{{ page }} of {{ total_page }}</p>
            </div>
            <v-btn
              small
              min-width="10px"
              color="#00b4cc"
              dark
              class="pa-2 custom_shadow"
              @click="pagination('next')"
              ><v-icon small>mdi-arrow-right</v-icon></v-btn
            >
          </div>
        </div>
      </div>
    </v-card>
    <div class="pa-2">
      <CreateUser
        v-bind:dialogCreate="dialogCreate"
        @close="dialogCreate = false"
        @refetch="refetch()"
      />
      <UpdateUser
        v-bind:dialogUpdateUser="dialogUpdateUser"
        @close="dialogUpdateUser = false"
        v-bind:dataUser="dataUser"
        @refetch="refetch()"
        @pwd="showPwd"
        ref="update"
      />
      <DeleteUser
        v-bind:dialogDeleteUser="dialogDeleteUser"
        v-bind:dataUser="dataUser"
        @refetch="refetch()"
        @close="dialogDeleteUser = false"
      />
      <ChangePassword
        v-bind:dialogChangePwd="dialogChangePwd"
        @close="dialogChangePwd = false"
        @walrus="backFromChangePwd"
        v-bind:dataUser="dataUser"
      />
      <ChangeStatusUser
        v-bind:dialogChangeStatus="dialogChangeStatus"
        @close="dialogChangeStatus = false"
        v-bind:dataUser="dataUser"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import AllUser from "../../../components/Pengguna/View/AllUser.vue";
import CreateUser from "../Create/createUser.vue";
import DeleteUser from "../Delete/deleteUser.vue";
import ChangePassword from "../Update/changePassword.vue";
import ChangeStatusUser from "../Update/changeStatusUser.vue";
import UpdateUser from "../Update/updateUser.vue";
export default {
  name: "Hospital",
  computed: {
    ...mapState({
      users: (state) => state.user.active_user,
      role_akun: (state) => state.role,
    }),
  },

  components: {
    CreateUser,
    DeleteUser,
    UpdateUser,
    ChangePassword,
    ChangeStatusUser,
    AllUser,
  },
  data() {
    return {
      loading: false,
      dataUser: null,
      role: "",
      page: 1,
      find: "",
      limit: 10,
      tipe_fasyankes: 1,
      menu: false,
      menuLimit: false,
      dialogDeleteUser: false,
      dialogUpdateUser: false,
      dialogChangePwd: false,
      dialogChangeStatus: false,
      dialogCreate: false,
      items: ["Foo", "Bar", "Fizz", "Buzz"],
      headers: [
        {
          text: "Nama Instansi",
          align: "start",
          value: "nama_fasyankes",
        },
        {
          text: "Nama Pengguna",
          align: "start",
          value: "full_name",
        },
        {
          text: "No. Telepon",
          align: "start",
          value: "telpon",
        },
        { text: "Status", value: "status" },
        { text: "Aktivasi", sortable: false, value: "isActive" },
        { text: "Aksi", sortable: false, value: "actions" },
      ],
      hospitals: [],
      isNext: "",
      isPrev: "",
      total_page: null,
    };
  },
  mounted() {
    this.allFilter();
  },

  methods: {
    refetch() {
      this.allFilter();

      this.dialogCreate = false;
    },
    backFromChangePwd() {
      this.dialogChangePwd = false;
      this.dialogUpdateUser = true;
    },
    sliceAndEllipsis(value) {
      return value.length > 30 ? value.slice(0, 30) + "..." : value;
    },
    getUser() {
      this.loading = true;
      this.$store
        .dispatch("user/getUserActive")
        .then(() => {
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    filterRole() {
      this.loading = true;
      this.menu = false;
      let key = this.role.replace(/\s/g, "").toLocaleLowerCase();
      let body = {
        [key]: this.role,
      };
      let data = {
        status: "active",
        body: body,
      };
      this.$store
        .dispatch("user/filterRole", data)
        .then((data) => {
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    changeLimit(val) {
      this.limit = val;
      this.allFilter("limit");
    },
    pagination(state) {
      if (state == "next") {
        if (this.isNext) {
          this.page++;
          this.allFilter();
        }
      } else {
        if (this.isPrev) {
          this.page--;
          this.allFilter();
        }
      }
    },
    showPwd() {
      this.dialogUpdateUser = false;
      this.dialogChangePwd = true;
    },
    async allFilter(type) {
      this.page = type == "limit" ? 1 : this.page;
      this.loading = true;
      this.menu = false;
      let body = {
        role: this.role,
        find: this.find,
        limit: this.limit,
        page: this.page,
        tipe_fasyankes: this.tipe_fasyankes,
      };
      let data = {
        status: "active",
        body: body,
      };

      const res = await this.$store.dispatch("user/filterAllinOne", data);
      const hospitals = res.data.map((user) => {
        return {
          ...user,
          status: user.isActive === "Active" ? true : false,
        };
      });
      this.hospitals = hospitals;
      this.loading = false;
      this.isNext = res.next_page_url;
      this.isPrev = res.prev_page_url;
      this.total_page = Math.ceil(res.total / this.limit);
    },
    delUser(item) {
      console.log(item);
      this.dataUser = item;
      this.dialogDeleteUser = true;
    },
    changeStatus(item) {
      this.dataUser = item;
      this.dialogChangeStatus = true;
    },
    viewUser(item) {
      console.log(item);
      this.dataUser = item;
      this.dialogUpdateUser = true;
      this.$refs.update.getPerson(item.id);
    },
    row_classes(item) {
      let idx = this.hospitals.indexOf(item);
      if (idx % 2 == 0) {
        return "strip_blue"; //can also return multiple classes e.g ["orange","disabled"]
      }
    },
  },
};
</script>

<style></style>
