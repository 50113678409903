<template>
  <div class="padd_content">
    <div class="d-flex pa-2 mb-3 align-center">
      <BlueCircle class="mr-2" />
      <h3 class="color_default">Data Dinas Kesehatan</h3>
    </div>
    <v-card
      class="rounded-max custom_card pa-8 mb-5 mx-2"
      v-if="role == 'Administrator'"
    >
      <div class="d-flex align-center">
        <h4 class="color_default mr-5">Akses Login</h4>
        <div
          class="switch_active d-flex"
          v-if="loginActive"
          @click="switchStatusLogin(1)"
        >
          <div style="width: 70%" class="d-flex justify-center">
            <b>Aktif</b>
          </div>
          <div class="btn_switch">x</div>
        </div>
        <div class="switch_unactive d-flex" v-if="!loginActive">
          <div class="btn_switch">x</div>
          <div style="width: 70%" class="d-flex justify-center">
            <b>Non-Aktif</b>
          </div>
        </div>
      </div>
    </v-card>

    <v-tabs
      @change="onTabChange"
      v-model="tab"
      background-color="#cdf0f7"
      class="px-2 pb-2"
    >
      <v-tab
        v-for="(tab, i) in tabs"
        :key="i"
        :href="'#' + tab"
        class="text-capitalize mr-3 header_tab"
        active-class="active_tab"
      >
        <h3>{{ tab }}</h3>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab" style="background: none" class="px-2">
      <v-tab-item v-for="(tab, i) in tabs" :key="i" :value="tab">
        <TableDinkes
          :users.sync="users"
          :role.sync="ROLES.Administrator"
          :page.sync="page"
          :tab="tab"
          :limit.sync="limit"
          :total="total"
          :find.sync="find"
          :has_prev="!!prev_page_url"
          :has_next="!!next_page_url"
          @refetch="getUsers"
        />
        <div style="height: 100px"></div>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { mapState } from "vuex";
import BlueCircle from "../../components/General/BlueCircle";
import UpdateAllStatus from "../../components/Pengguna/Update/updateAllStatus.vue";
import DinkesKabupatenKota from "../../components/Pengguna/View/DinkesKabupatenKota";
import DinkesProvince from "../../components/Pengguna/View/DinkesProvince";
import TableUser from "../../components/Pengguna/View/TableUser.vue";
import TableDinkes from "../../components/Pengguna/View/TableDinkes.vue";
import Hospital from "../../components/Pengguna/View/hospital.vue";
import Puskesmas from "../../components/Pengguna/View/puskesmas.vue";
import UserActive from "../../components/Pengguna/View/userActive.vue";
import UserUnactive from "../../components/Pengguna/View/userUnactive .vue";
import {
  FASYANKES_TYPE,
  FASYANKES_TYPE_NAME,
  ROLES,
} from "../../constants/constant";

export default {
  components: {
    UpdateAllStatus,
    UserActive,
    UserUnactive,
    BlueCircle,
    Hospital,
    Puskesmas,
    DinkesProvince,
    DinkesKabupatenKota,
    TableUser,
    TableDinkes,
  },
  computed: {
    ...mapState({
      // users: (state) => state.user.all_user,
      // role: (state) => state.role,
    }),
  },
  data() {
    return {
      ROLES,
      users: [],
      page: 1,
      limit: 10,
      total: 0,
      total_page: 0,
      next_page_url: "",
      prev_page_url: "",
      role: "",
      find: "",
      loading: false,
      tab: "",
      tabs: [
        ROLES.PublicHealthService_Province,
        ROLES.PublicHealthService_Regency,
      ],
      updateLoginStatus: false,
      loginActive: true,
    };
  },
  mounted() {
    this.getUsers();
  },
  methods: {
    async getUsers() {
      this.loading = true;
      this.menu = false;
      let body = {
        role: this.role,
        find: this.find,
        limit: this.limit,
        page: this.page,
        tipe_fasyankes: this.tipe_fasyankes,
      };

      const res = await this.$store.dispatch("user/filterAllinOne", { body });
      this.users = res.data;
      this.total = res.total;
      this.total_page = Math.ceil(res.total / this.limit);
      this.next_page_url = res.next_page_url;
      this.prev_page_url = res.prev_page_url;
      this.loading = false;
    },
    switchStatusLogin() {
      this.updateLoginStatus = true;
    },
    onTabChange() {
      this.role = this.tab;
      this.page = 1;
      this.limit = 10;
      this.find = "";
      this.tipe_fasyankes = "";
      this.getUsers();
    },
  },
  watch: {
    find() {
      this.page = 1;
      this.getUsers();
    },
    limit() {
      this.page = 1;
      this.getUsers();
    },
    page() {
      this.getUsers();
    },
    tipe_fasyankes() {
      this.getUsers();
    },
    role() {
      if (this.role === FASYANKES_TYPE_NAME[FASYANKES_TYPE.Hospital]) {
        this.role = ROLES.HealthServiceFacility;
        this.tipe_fasyankes = FASYANKES_TYPE.Hospital;
      }

      if (
        this.role === FASYANKES_TYPE_NAME[FASYANKES_TYPE.PublicHealthCenter]
      ) {
        this.role = ROLES.HealthServiceFacility;
        this.tipe_fasyankes = FASYANKES_TYPE.PublicHealthCenter;
      }

      this.getUsers();
    },
  },
};
</script>

<style scoped>
.padd_content {
  padding: 40px 50px;
}
</style>