<template>
  <v-row justify="start">
    <v-dialog v-model="dialogAlert" persistent max-width="700px">
      <v-card class="rounded-max hide_overflow" flat>
        <div v-if="detail">
          <div class="d-flex">
            <div class="side_left py-3 px-5">
              <v-row>
                <v-col cols="12"></v-col>
                <v-col cols="12">
                  <p class="ma-0 small_txt color_txt">Lat/Long</p>
                  <div>
                    <b>{{ detail.longlat }}</b>
                  </div>
                </v-col>
                <v-col cols="12">
                  <p class="ma-0 small_txt color_txt">Provinsi</p>
                  <div class="d-flex">
                    <b>{{ detail.provinsi_name }}</b
                    >,
                    <p class="ml-1 ma-0 small_txt color_txt">
                      Kode: {{ detail.provinsi_id }}
                    </p>
                  </div>
                </v-col>
                <v-col cols="12">
                  <p class="ma-0 small_txt color_txt">Kabupaten/Kota</p>
                  <div class="d-flex">
                    <b>{{ detail.kabupaten_name }}</b
                    >,
                    <p class="ml-1 ma-0 small_txt color_txt">
                      Kode: {{ detail.kabupatenKota_id }}
                    </p>
                  </div>
                </v-col>
                <v-col cols="12">
                  <p class="ml-1 ma-0 small_txt color_txt">Kecamatan</p>
                  <div class="d-flex">
                    <b>{{ detail.kecamatan_name ? detail.kecamatan_name : "-" }}</b
                    >,
                    <p class="ma-0 small_txt color_txt">
                      Kode:
                      {{ detail.kecamatan_id ? detail.kecamatan_id : "-" }}
                    </p>
                  </div>
                </v-col>
              </v-row>
            </div>
            <div class="side_right">
              <div class="closer d-flex justify-end pa-1">
                <v-btn icon small @click="$emit('close')">
                  <v-icon small>mdi-close</v-icon>
                </v-btn>
              </div>
              <div class="pa-3">
                <v-row>
                  <v-col cols="12"><b>Profil</b></v-col>
                  <v-col cols="6">
                    <p class="ma-0 small_txt color_txt">Kepemilikan</p>
                    <div class="like_input pa-3">
                      {{ detail.kepemilikan }}
                    </div>
                  </v-col>
                  <v-col cols="6">
                    <p class="ma-0 small_txt color_txt">Kelas</p>
                    <div class="like_input pa-3">
                      {{ detail.kelas }}
                    </div>
                  </v-col>
                  <v-col cols="6">
                    <p class="ma-0 small_txt color_txt">
                      BOR (Bed Occupancy Rate)
                    </p>
                    <div class="like_input pa-3">
                      {{ detail.persenBOR }}
                    </div>
                  </v-col>
                  <v-col cols="6">
                    <p class="ma-0 small_txt color_txt">Tempat Tidur</p>
                    <div class="like_input pa-3">
                      {{ detail.jumlahTempatTidur }}
                    </div>
                  </v-col>
                  <v-col cols="6">
                    <p class="ma-0 small_txt color_txt">
                      Karakteristik Wilayah
                    </p>
                    <div class="like_input pa-3">
                      {{ detail.karakteristikWilayah }}
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>
          </div>
          <v-overlay
            :absolute="true"
            color="white"
            opacity="1"
            :value="loading"
          >
            <v-progress-circular
              indeterminate
              class="mr-2"
              color="#00b4cc"
            ></v-progress-circular>
            <b class="color_default">Loading...</b>
          </v-overlay>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
// import Swal from "sweetalert2";

export default {
  name: "alertFormulir",
  props: ["dialogAlert", "body"],
  computed: {
    ...mapState({
      err: (state) => state.auth.errMsg,
      solve: (state) => state.auth.response,
    }),
  },
  data() {
    return {
      role: "",
      loading: false,
      detail: null,
    };
  },
  methods: {
    getData(type, id) {
      this.loading = true;
      let data = {
        type: type,
        id: id,
      };
      this.$store
        .dispatch("dataFasyankes/detailFasyankes", data)
        .then((data) => {
          this.detail = data[0];
          this.loading = false;
        });
    },
    
  },
};
</script>

<style scoped>
.side_left {
  width: 40%;
  background: #faf9f9;
}
.side_right {
  width: 60%;
}
.like_input {
  border-radius: 8px;
  background: #d2f2f6;
}
.closer {
  width: 100%;
}
.temp_form {
  padding: 20px 25px;
}
.hide_overflow {
  overflow: hidden;
}
.family {
  font-family: Arial, Helvetica, sans-serif;
}
.small-txt {
  font-size: small !important;
}
</style>