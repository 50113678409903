<template>
  <v-row justify="start">
    <v-dialog v-model="updateLoginStatus" persistent max-width="430px">
      <v-card class="rounded-max hide_overflow" flat>
        <div class="closer d-flex justify-end pa-1">
          <v-btn icon small @click="$emit('close')">
            <v-icon small>mdi-close</v-icon>
          </v-btn>
        </div>
        <div class="d-flex flex-column align-center justify-center" style="height: 230px">
          <p class="color-txt">Anda yakin ingin menonaktifkan akses login?</p>
          <div class="d-flex">
              <v-btn class="text-capitalize py-6 mr-4" @click="deleteUser()" dark color="#00b4cc" width="100px" depressed>Ya</v-btn>
              <v-btn class="text-capitalize py-6" @click="$emit('close')" outlined color="#00b4cc" width="100px" depressed>Batal</v-btn>
          </div>
          <div style="height:30px"></div>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
import Swal from "sweetalert2";

export default {
  name: "updateLoginStatus",
  props: ["updateLoginStatus"],
  computed: {
    ...mapState({
      err: (state) => state.auth.errMsg,
      solve: (state) => state.auth.response,
    }),
  },
  data() {
    return {
      role: "",
      loading: false,
    };
  },
  methods: {
    updateAccess() {
      this.loading = true;
      this.$store
        .dispatch("user/deleteUser", data)
        .then((data) => {
          console.log(data);
          this.loading = false;
          this.Swal("error", err.message);
          this.$emit('close')
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.Swal("error", err.message);
        });
    },
    Swal(type, msg) {
      Swal.fire({
        icon: type,
        title: msg,
        showConfirmButton: false,
        position: "top-end",
        timer: 3000,
        toast: true,
        customClass: {
          title: "family",
        },
      });
    },
  },
};
</script>

<style scoped>
.side_left {
  width: 40%;
  height: 400px;
  border-radius: 0 !important;
}
.side_right {
  width: 60%;
}
.closer {
  width: 100%;
}
.temp_form {
  padding: 20px 25px;
}
.hide_overflow {
  overflow: hidden;
}
.family {
  font-family: Arial, Helvetica, sans-serif;
}
.small-txt {
  font-size: small !important;
}
</style>